import React from 'react'
import styled from 'styled-components'
import {
  Button,
  Illustrations,
  Text,
  color,
  size
} from '../../../youtalk-storybook/src/ui'
import { isBrowser } from '../../atoms/root'
import { navigate } from 'gatsby'
import { useFormattedCatalogFiltersData } from '../RegistrationModals/hooks/useFormattedCatalogFiltersData'
import { usePsyCardStorage } from './usePsyCardStorage'

const TextWithIllustration = styled(({ className }) => (
  <div className={className}>
    <Illustrations.IllustrationCalendarError />
    <Text.Average semiBold>
      Чтобы записаться к этому психологу, отправьте нам заявку.
    </Text.Average>
  </div>
))`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media (max-width: ${size.sm}) {
    flex-direction: row;
    gap: 16px;
    height: 74px;

    ${Illustrations.IllustrationCalendarError} {
      width: 86px;
      height: 74px;
    }
  }
`

// eslint-disable-next-line no-unused-vars
export const ApplicationFormBlock = styled(({ className }) => {
  if (!isBrowser) return null

  const [storedValue] = usePsyCardStorage()

  const filtersData = useFormattedCatalogFiltersData(storedValue)

  return (
    <div className={className}>
      <TextWithIllustration />
      <Button.NewPrimary
        onClick={() =>
          navigate(`/application-form/?id=${filtersData.psychologistID}`, {
            state: {
              filters: filtersData
            }
          })
        }
      >
        Отправить заявку
      </Button.NewPrimary>
    </div>
  )
})`
  background: ${color.background};
  width: 384px;
  height: fit-content;
  flex-direction: column;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 24px;
  padding: 24px;
  border-radius: 16px;
  position: sticky;
  top: 15px;

  ${Button.NewPrimary} {
    width: 100%;
  }

  @media (max-width: ${size.md}) {
    width: 306px;
  }

  @media (max-width: ${size.sm}) {
    padding: 20px;
    gap: 16px;
    bottom: 0px;
    z-index: 101;
    width: 100%;
  }
`
