import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

export const useFakeTimer = () => {
  const [timeRemaining, setTimeRemaining] = useState('')

  useEffect(() => {
    const updateCountdown = () => {
      const now = dayjs()
      const targetDate = dayjs().add(1, 'day').startOf('day')

      const difference = targetDate.diff(now, 'seconds')

      if (difference <= 0) {
        setTimeRemaining('24:00:00')
        setTimeout(updateCountdown, 1000)
        return
      }

      const hours = Math.floor(difference / (60 * 60))
      const minutes = Math.floor((difference % (60 * 60)) / 60)
      const seconds = Math.floor(difference % 60)

      const formattedTime = `${String(hours).padStart(2, '0')}:${String(
        minutes
      ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`

      setTimeRemaining(formattedTime)
    }

    updateCountdown()

    const interval = setInterval(updateCountdown, 1000)

    return () => clearInterval(interval)
  }, [])

  return timeRemaining
}
