/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable max-lines-per-function */
import { CatalogRegistrationStep } from '../vars'
import { Event as GAEvent } from '../../../../components/GA'
import { RegistrationSource } from '../../../../atoms/mappers/gqlEnums/registrationSourse'
import { SourceID } from '../../../../atoms/mappers/wizard/enums/sourceId'
import { amplitudeIdentifyLeadId } from '../../../../components/amplitude/identify'
import { getAnalyticsDataFromCookies } from '../../../../atoms/useFormValues'
import { getUTM } from '../../../../atoms/initialQueryParams'
import { pick } from 'rambda'
import { sendCatalogRegistrationRequestInputSchema } from '../schema'
import { useCatalogRegistrationModalContext } from './useCatalogRegistrationModalContext'
import { useErrorModalContext } from '../../hooks/useErrorProvider'
import { useFormattedCatalogFiltersData } from '../../hooks/useFormattedCatalogFiltersData'
import { usePsyCardStorage } from '../../../Information/usePsyCardStorage'
import { useSendRegistrationRequestMutation } from '../../query'

export const useIdentityStepSubmit = (psychologist) => {
  const [storedValue] = usePsyCardStorage()
  const formattedCatalogFiltersData = useFormattedCatalogFiltersData(
    storedValue,
    psychologist
  )
  const context = useCatalogRegistrationModalContext()
  const errorContext = useErrorModalContext()
  const utm = getUTM()
  const [firstStepRegisterMutation] = useSendRegistrationRequestMutation()

  return async (values) => {
    try {
      const input = {
        phone: values.tel,
        name: context.formData.name,
        age: Number(context.formData.age),
        userRegistrationPreferences: {
          ...context.formData.userRegistrationPreferences,
          ...pick(
            ['aboutPsychologist', 'psychologistID'],
            formattedCatalogFiltersData
          )
        },
        registrationSource: RegistrationSource.Catalog,
        attempt: values.attempt ?? 0,
        analyticsData: {
          sourceId: SourceID.UC_12XK08,
          utmSource: utm.utm_source,
          utmCampaign: utm.utm_campaign,
          utmMedium: utm.utm_medium,
          utmTerm: utm.utm_term,
          utmContent: utm.utm_content,
          uid: utm.uid,
          gclid: utm.gclid,
          pointOfContact: 'wizard_subscription',
          cityadsClickId: utm.click_id,
          ...getAnalyticsDataFromCookies()
        }
      }
      try {
        await sendCatalogRegistrationRequestInputSchema.validate(input)
        GAEvent.submitLeadCreate()
      } catch (error) {
        return { tel: error.message }
      }
      const response = await firstStepRegisterMutation({
        variables: {
          input
        }
      })

      if (response.data.sendRegistrationRequest?.token) {
        amplitudeIdentifyLeadId(
          response.data.sendRegistrationRequest?.bitrixLeadId
        )
        context.updateContext({
          step: CatalogRegistrationStep.CODE,
          token: response.data.sendRegistrationRequest?.token,
          datetime: response.data.sendRegistrationRequest?.datetime,
          canBeRequestAgain: false,
          tel: values.tel,
          attempt: 1
        })
        return response.data.sendRegistrationRequest?.token
      } else {
        errorContext.onServerError()
      }
    } catch (err) {
      console.log(err)
      errorContext.onServerError()
    }
  }
}
