import React from 'react'
import { CodeStepForm } from './form'
import { RegistrationRoute } from '../../../../components/amplitude/auth/vars'
import { usePsychologistProfileContext } from '../../../../pagesTemplates/psychologist'
import { useTrackCodeEnterEffect } from '../../../../components/amplitude/auth/trackCodeEnter'
// import { useTrackRegistraionPasswordCreateEffect } from '../../../components/amplitude/trackRegistraionPasswordCreate'

export const CodeStep = ({ onCodeSubmit }) => {
  const psychologist = usePsychologistProfileContext()?.psychologist
  const badgesExist = psychologist?.badges && psychologist?.badges.length > 0
  const badgeValue = badgesExist ? 'high_rating' : 0

  useTrackCodeEnterEffect({
    route: RegistrationRoute.Wizard,
    badge: badgeValue
  })

  return <CodeStepForm onSubmit={onCodeSubmit} />
}
