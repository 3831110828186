import styled from 'styled-components'
import { FormButton } from './FormButton'
import { ReactComponent as InfoSVG } from './img/info.svg'
import {
  Modal,
  PopupBody as ModalBody,
  PopupContent as ModalContent,
  color
} from '../../../youtalk-storybook/src/ui'
import { Text } from '../../atoms/Text'
import { size } from '../../constants'

export const ModalButtonGroup = styled.div`
  && {
    width: 100%;
    padding-top: 12px;

    @media (max-width: ${size.sm}) {
      background: ${color.background};
      box-shadow: 3px -3px 6px rgba(25, 28, 31, 0.06),
        3px -1px 2px rgba(25, 28, 31, 0.06), 3px 1px 0px rgba(25, 28, 31, 0.04);

      width: calc(100% + 40px);
      z-index: 10;
      max-width: auto;
      padding: 16px 20px;
      transform: translateX(-20px);
      position: sticky;
      bottom: 0;

      & ${FormButton} {
        flex-grow: 1;
      }
    }

    @media (max-width: ${size.xs}) {
      padding: 16px 16px;
      width: calc(100% + 32px);
      transform: translateX(-16px);
    }
  }
`

export const Title = styled(Text.Large)`
  @media (max-width: ${size.sm}) {
    font-size: 18px;
    line-height: 26px;
  }
`

export const SmsInfoBlock = styled.div`
  width: 100%;
  display: flex;
  padding: 8px;
  background-color: #f0f9ff;
  justify-content: center;
  gap: 8px;
`

export const SmsInfoSvg = styled(InfoSVG)``

export const SmsInfoText = styled(Text.Average)`
  font-weight: 500;
  font-size: 14px;
  width: 100%;
`

export const Paragraph = styled(Text.Average)`
  font-weight: 500;
  width: 100%;
`

export const FormHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const FormItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const FormWrapWithoutButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const FormMain = styled.form`
  display: flex;
  flex-direction: column;
  height: inherit;
  gap: 24px;
  flex-grow: 1;

  @media (max-width: ${size.sm}) {
    width: 100%;
  }
`

export const AuthModal = styled(Modal)`
  overflow-y: auto;
  height: var(--height);
  z-index: 13;

  ${ModalContent} {
    max-width: 425px;
    width: 100%;
    overflow: visible;
    display: flex;
    flex-direction: column;
  }

  ${FormWrapWithoutButton} {
    flex: 1 0 auto;
  }

  ${ModalButtonGroup} {
    flex: 0 0 auto;
  }

  @media (max-width: ${size.sm}) {
    overflow-y: unset;

    & ${ModalBody} {
      overflow-y: auto;
      height: var(--height);
    }

    & ${ModalContent} {
      height: unset;
      max-height: none;
      max-width: 100vw;
      padding-bottom: 0px;
      min-height: var(--height);
    }
  }
`
