/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react'
import createDecorator from 'final-form-focus'
import styled from 'styled-components'
import { ControlMiniCheckboxes } from '../../../../molecules/FormItem'
import { Form as FinalForm } from 'react-final-form'
import { FormButton } from '../../FormButton'
import {
  FormHeader,
  FormItems,
  FormMain,
  FormWrapWithoutButton,
  ModalButtonGroup,
  Paragraph,
  SmsInfoBlock,
  SmsInfoSvg,
  SmsInfoText,
  Title
} from '../../index.styles'
import { InternationalTelephone } from '../../../Form/Input'
import { documents } from '../../../../../static/db.json'
import { notEmpty } from '../../../../atoms/FormItems/validators/notEmpty'
import {
  submit,
  useInitialFormValuesAfterDOMLoad,
  useInitialFormValuesFromParams
} from '../../../../atoms/useFormValues'
import { subscription } from '../vars'
import { useCatalogRegistrationModalContext } from '../hooks/useCatalogRegistrationModalContext'

const focusOnError = createDecorator()

const AgreementCheckbox = () => {
  const Label = () => (
    <Paragraph>
      {'Я соглашаюсь с '}
      <a href={documents.urlPolicy} rel="noreferrer" target="_blank">
        политикой обработки персональных данных
      </a>
      {' и принимаю '}
      <a href={documents.urlAgreement} rel="noreferrer" target="_blank">
        условия пользовательского соглашения
      </a>
    </Paragraph>
  )
  return (
    <ControlMiniCheckboxes
      fill
      required
      options={[{ name: 'agreement', label: <Label />, requiredField: true }]}
      validate={notEmpty}
    />
  )
}

const Form = styled(({ className, handleSubmit, restart, errors, values }) => {
  useInitialFormValuesFromParams([{ fieldName: 'promo' }])
  useInitialFormValuesAfterDOMLoad([{ fieldName: 'tel', value: '+7' }])
  const { updateContext } = useCatalogRegistrationModalContext()
  const onChangeCountry = useCallback(
    (country) => updateContext({ country }),
    []
  )

  return (
    <FormMain
      noValidate
      className={className}
      onSubmit={submit({
        handleSubmit,
        restart,
        errors,
        initialValues: values
      })}
    >
      <FormWrapWithoutButton>
        <FormHeader>
          <Title bold>Регистрация</Title>
          <Paragraph>
            Создайте аккаунт, чтобы начать заниматься с психологом
          </Paragraph>
        </FormHeader>
        <FormItems>
          <InternationalTelephone onChangeCountry={onChangeCountry} />
          <AgreementCheckbox />
        </FormItems>
      </FormWrapWithoutButton>
      <ModalButtonGroup>
        <FormButton>Зарегистрироваться</FormButton>
      </ModalButtonGroup>
      <SmsInfoBlock>
        <SmsInfoSvg />
        <SmsInfoText>
          Никаких звонков, только SMS о статусе сессий. А ещё на этот номер
          напишет выбранный психолог в мессенджер
        </SmsInfoText>
      </SmsInfoBlock>
    </FormMain>
  )
})``

export const IdentityStepForm = ({ onSubmit }) => {
  const { formData } = useCatalogRegistrationModalContext()

  return (
    <FinalForm
      initialValues={{
        agreement: Boolean(formData.agreement),
        tel: formData.tel
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, form: { restart }, errors, values }) => (
        <Form
          errors={errors}
          handleSubmit={handleSubmit}
          restart={restart}
          values={values}
        />
      )}
      subscription={subscription}
      validate={() => {}}
    />
  )
}
