import React from 'react'
import { IdentityStepForm } from './form'
// import { useTrackRegistraionAccountCreateEffect } from '../../../components/amplitude/trackRegistraionAccountCreate'
import { useTrackSignUpEffect } from '../../../../components/amplitude/auth/trackSignUp'

export const IdentityStep = ({ onIdentitySubmit }) => {
  // useTrackRegistraionAccountCreateEffect()
  useTrackSignUpEffect()
  return <IdentityStepForm onSubmit={onIdentitySubmit} />
}
